<template>
    <div>
        <div class="p-2 rounded">
            <div class="flex gap-2 items-center">
                <input-rule v-model="formSearch.ref" type="text" :label="$tt('Référence')" :label-inline="false" custom="text-xs" />
                <input-rule v-model="formSearch.design" type="text" :label="$tt('Désgination')" :label-inline="false" custom="text-xs" />
            </div>
        </div>
        <div class="flex items-center">
            <div class="mt-4 grid grid-cols-6">
                <!-- Selection de la piece -->
                <div class="table-pieces flex items-center">
                    <div class="w-[95%]">
                        <div class="text-xs font-bold text-center text-blue-500 py-2 bg-blue-100 rounded mb-1">{{$tt('Référentiel')}}</div>
                        <table class="border">
                            <thead class="bg-slate-100 text-xs text-left border-b">
                                <tr>
                                    <th class="py-1 px-2 border-r">{{ $tt('Référence') }}</th>
                                    <th class="py-1 px-2">{{ $tt('Désignation') }}</th>
                                </tr>
                            </thead>
                            <tbody class="text-xs h-[200px]">
                                <tr v-for="(piece, pieceIndex) in pieces" :key="'ref-piece-' + pieceIndex" @click="selectPiece(piece, 'referentiel')" class="duration-200 cursor-pointer" :class="selectedPieceRef && selectedPieceRef['ref'] == piece['ref'] ? (selectedTypePiece == 'equivalence' ? 'bg-slate-300' : 'bg-blue-500 text-white') : 'hover:bg-blue-100'">
                                    <td class="py-1 px-2 border-r">{{piece['ref']}}</td>
                                    <td class="py-1 px-2" :title="piece['design']">{{piece['design']}}</td>
                                </tr>
                                <tr>
                                    <td class="py-1 px-2" colspan="2">
                                        <button @click="displayModalAddPiece" class="px-2 py-1 bg-slate-100 hover:bg-slate-200 duration-200 rounded"><i class="fas fa-plus mr-1"></i>{{ $tt('Ajouter une pièce') }}</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-if="selectedTypePiece == 'referentiel'" class="w-[5%] h-[100%] relative">
                        <div class="border-t-[2px] border-blue-400 absolute left-[0%] right-[0%] top-[50%]"></div>
                    </div>
                </div>

                <!-- Stock -->
                <div class="col-span-3 grid grid-cols-6">
                    <div class="table-pieces flex items-center col-span-4">
                        <div v-if="selectedTypePiece" class="w-[15%] h-[100%] relative">
                            <div class="border-t-[2px] border-blue-400 absolute left-[0%] right-[0%] top-[50%] bottom-[0%]"></div>
                            <div class="border-r-[2px] border-blue-400 absolute left-[0%] top-[50%] bottom-[0%]"></div>
                            <i class="fas fa-caret-right text-xl text-blue-400 absolute top-[50%] right-0 -mt-[12px]"></i>
                        </div>
                        <div v-if="selectedTypePiece" class="w-[75%]">
                            <div class="text-xs font-bold text-center text-orange-500 py-2 bg-orange-100 rounded mb-1">Stock</div>
                            <div class="bg-slate-50 text-slate-600 text-center p-3 rounded text-xs font-bold cursor-not-allowed" v-if="stocks.length <= 0">
                                <div class="flex justify-center" v-if="loadingStock">
                                    <spinner :size="10" />
                                </div> 
                                <template v-if="!loadingStock">
                                    <i class="fas fa-ban mr-1"></i> {{$tt('Aucune pièce en stock')}}
                                </template>
                            </div>
                            <table v-if="stocks && stocks.length > 0" class="border">
                                <thead class="bg-slate-100 text-xs text-left border-b">
                                    <tr>
                                        <th class="py-1 border-r" style="width: 10px !important"></th>
                                        <th class="py-1 px-2 border-r" style="width: 35px !important">{{ $tt('Qte') }}</th>
                                        <th class="py-1 px-2 border-r" style="width: 75px !important">{{ $tt('Emplacement') }}</th>
                                        <th class="py-1 px-2 border-r" style="width: 75px !important">{{ $tt('Qte 2ème choix') }}</th>
                                    </tr>
                                </thead>
                                <tbody class="text-xs h-[200px]">
                                    <template v-for="(stock, stockIndex) in stocks">
                                        <tr :key="'ref-stock-' + stockIndex" @click="openStock(stock)" class="duration-200 cursor-pointer" :class="selectedStock && selectedStock.emplacement == stock.emplacement ? 'bg-orange-100 font-bold' : ''">
                                            <td class="py-1 border-r text-center" style="width: 10px !important">
                                                <i class="fas fa-chevron-right" v-if="!stock.open"></i>
                                                <i class="fas fa-chevron-down" v-if="stock.open"></i>
                                            </td>
                                            <td class="py-1 px-2 border-r" style="width: 35px !important">{{stock.qtt}}</td>
                                            <td class="py-1 px-2 border-r" style="width: 75px !important">{{stock.emplacement}}</td>
                                            <td class="py-1 px-2 border-r" style="width: 75px !important">X</td>
                                        </tr>
                                        <tr v-if="stock.open" :key="'ref-piece-stock-' + stockIndex">
                                            <td colspan="3" class="pl-[30px]">
                                                <table v-if="stocks && stocks.length > 0" class="border">
                                                    <thead class="bg-slate-100 text-xs text-left border-b">
                                                        <tr>
                                                            <th class="py-1 px-2 border-r" style="width: 35px !important">{{ $tt('Qte') }}</th>
                                                            <th class="py-1 px-2 border-r" style="width: 75px !important">{{ $tt('Fournisseur') }}</th>
                                                            <th class="py-1 px-2 border-r" style="width: 50px !important">{{ $tt('Prix HA') }}</th>
                                                            <th class="py-1 px-2 border-r" style="width: 50px !important">{{ $tt('Occasion') }}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="text-xs">
                                                        <tr v-for="(piece, pieceIndex) in stock.pieces" :key="'stock-' + pieceIndex" @click="selectStock(piece)" class="duration-200 cursor-pointer" :class="selectedStock && selectedStock.id == piece.id ? 'bg-orange-500 text-white' : 'hover:bg-orange-100'">
                                                            <td class="py-1 px-2 border-r" style="width: 35px !important">{{piece.qtt}}</td>
                                                            <td class="py-1 px-2 border-r" style="width: 75px !important">{{ piece.fournisseur?.nom || '-' }}</td>
                                                            <td class="py-1 px-2 border-r" style="width: 50px !important">{{ piece.prix_ha }} €</td>
                                                            <td class="py-1 px-2 border-r" style="width: 50px !important">
                                                                <div :class="selectedStock && selectedStock.id == piece.id ? 'text-white' : ''" v-if="piece.occasion">Occasion</div>
                                                                <div :class="selectedStock && selectedStock.id == piece.id ? 'text-white' : ''" v-if="!piece.occasion">Neuve</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <div class="w-[10%] h-[100%] relative" v-if="selectedStock">
                            <div class="border-t-[2px] border-orange-400 absolute left-[0%] right-[0%] top-[50%]"></div>
                            <i class="fas fa-caret-right text-xl text-orange-400 absolute top-[50%] right-0 -mt-[12px]"></i>
                        </div>
                    </div>

                    <!-- Qtt Stock -->
                    <div class="col-span-2">
                        <div class="h-[250px] flex items-center " v-if="selectedStock">
                            <div class="flex items-center px-4 py-4 w-[100%] text-xs font-bold text-orange-500 bg-orange-100 rounded" v-if="selectedStock">
                                <input-rule v-model="qttStock" type="number" :label="$tt('Quantité à consommer dans le stock')" :label-inline="false" custom="text-xs" :options="{min: 0, max: selectedStock?.qtt}" @input="setExpensiveAmount" />
                            </div>
                            <div class="w-[10%] h-[100%] relative" v-if="selectedStock && qttStock > 0">
                                <div class="border-t-[2px] border-orange-400 absolute left-[0%] right-[0%] top-[50%]"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Empty -->
                <div class="col-span-2"></div>



                <!-- Equivalence -->
                <div class="flex items-center">
                    <div class="w-[95%] mt-10" v-if="similars && similars.length > 0">
                        <div class="table-pieces">
                            <div class="text-xs font-bold text-center text-blue-500 py-2 bg-blue-100 rounded mb-1">Equivalences trouvées</div>
                            <table class="border">
                                <thead class="bg-slate-100 text-xs text-left border-b">
                                    <tr>
                                        <th class="py-1 px-2 border-r">{{ $tt('Référence') }}</th>
                                        <th class="py-1 px-2">{{ $tt('Désignation') }}</th>
                                    </tr>
                                </thead>
                                <tbody class="text-xs h-[200px]">
                                    <tr v-for="(piece, pieceIndex) in similars" :key="'ref-piece-' + pieceIndex" @click="selectPiece(piece, 'equivalence')" class="duration-200 cursor-pointer" :class="selectedPiece && selectedPiece['ref'] == piece['ref'] ? 'bg-blue-500 text-white' : 'hover:bg-blue-100'">
                                        <td class="py-1 px-2 border-r">{{piece['ref']}}</td>
                                        <td class="py-1 px-2" :title="piece['design']">{{piece['design']}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div v-if="selectedTypePiece == 'equivalence'" class="w-[5%] h-[100%] relative">
                        <div class="border-t-[2px] border-blue-400 absolute left-[0%] right-[0%] top-[50%]"></div>
                    </div>
                </div>

                <!-- Commande -->
                <div class="col-span-3 grid grid-cols-6">
                    <div class="table-pieces flex items-center col-span-4">
                    <div v-if="selectedTypePiece" class="w-[15%] h-[100%] relative">
                        <div class="border-t-[2px] border-blue-400 absolute left-[0%] right-[0%] top-[50%] bottom-[0%]"></div>
                        <div class="border-r-[2px] border-blue-400 absolute top-[0%] bottom-[50%] left-[0%]"></div>
                        <i class="fas fa-caret-right text-xl text-blue-400 absolute top-[50%] right-0 -mt-[12px]"></i>
                    </div>
                    <div v-if="selectedTypePiece" class="w-[75%] mt-10">
                        <div class="text-xs font-bold text-center text-amber-500 py-2 bg-amber-100 rounded mb-1">{{ $tt('Commande') }}</div>
                        <table class="border">
                            <thead class="bg-slate-100 text-xs text-left border-b">
                                <tr>
                                    <th class="py-1 px-2" style="width: 45% !important">{{ $tt('Fournisseur') }}</th>
                                    <th class="py-1 px-2" style="width: 25% !important"><div class="flex items-center gap-1"><tooltip :msg="$tt('Prix d\'achat moyen pondéré')" custom="text-slate-800" /> {{$tt('PHAMP')}}</div></th>
                                    <th class="py-1 px-2" style="width: 30% !important"><div class="flex items-center gap-1"><tooltip :msg="$tt('Délai moyen')" custom="text-slate-800" /> {{$tt('Délai M.')}}</div></th>
                                </tr>
                            </thead>
                            <tbody class="text-xs h-[200px]">
                                <tr v-for="(fournisseur, fournisseurIndex) in fournisseurs" :key="'ref-fournisseur-' + fournisseurIndex" class="duration-200 cursor-pointer" @click="selectFournisseur(fournisseur)" :class="selectedFournisseur && selectedFournisseur.id == fournisseur.id && selectedFournisseur.prix_moyen == fournisseur.prix_moyen ? 'bg-amber-500 text-white' : 'hover:bg-amber-100'">
                                    <td class="py-1 px-2" style="width: 45% !important">{{fournisseur.nom}}</td>
                                    <td class="py-1 px-2" style="width: 25% !important">{{ fournisseur.prix_moyen }} €</td>
                                    <td class="py-1 px-2" style="width: 30% !important">-</td>
                                </tr>
                                <tr>
                                    <td class="py-1 px-2" colspan="3"><button @click="$modal.show('modal-add-fournisseur-commande')" class="px-2 py-1 bg-amber-200 hover:bg-amber-300 text-amber-700 rounded"><i class="fas fa-plus mr-0.5"></i> {{ $tt('Choix du fournisseur') }}</button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-if="selectedFournisseur" class="w-[10%] h-[100%] relative">
                        <div class="border-t-[2px] border-amber-400 absolute left-[0%] right-[0%] top-[50%]"></div>
                        <i class="fas fa-caret-right text-xl text-amber-400 absolute top-[50%] right-0 -mt-[12px]"></i>
                    </div>
                </div>

                <!-- Qtt Commande -->
                <div class="flex items-center flex items-center col-span-2">
                    <div class="flex items-center px-4 py-4 w-[80%] text-xs font-bold text-amber-500 bg-amber-100 rounded" v-if="selectedFournisseur">
                        <input-rule v-model="qttFournisseur" type="number" :label="$tt('Quantité à commander chez') + ' ' + selectedFournisseur?.nom" :label-inline="false" custom="text-xs" :options="{min: 0}" @input="setExpensiveAmount" />
                    </div>
                    <div class="w-[20%] h-[100%] relative" v-if="selectedFournisseur && qttFournisseur > 0">
                        <div class="border-t-[2px] border-amber-400 absolute left-[0%] right-[0%] top-[50%]"></div>
                    </div>
                </div>
            </div>

            <!-- Configuration de la pièce -->
            <div v-if="selectedTypePiece && (selectedFournisseur || selectedStock) && (qttStock > 0 || qttFournisseur > 0)" class="table-pieces flex items-center col-span-2">
                <div class="w-[20%] h-[100%] relative">
                    <template v-if="qttStock > 0">
                        <div class="absolute -top-[12%] left-[10%]">
                            <div class="flex items-center gap-1">
                                <i class="fas fa-box text-orange-400"></i>
                                <div class="text-orange-400 text-xs font-bold">{{$tt('Stock')}} ({{ qttStock }})</div>
                            </div>
                        </div>
                        <div class="border-t-[2px] border-orange-400 absolute left-[0%] right-[0%] -top-[5%]"></div>
                        <div class="border-r-[2px] border-orange-400 absolute left-[0%] -top-[45.6%] bottom-[105%]"></div>
                        <i class="fas fa-caret-right text-xl text-orange-400 absolute -top-[5%] right-0 -mt-[12px]"></i>
                    </template>
                    <template v-if="qttFournisseur > 0">
                        <div class="absolute top-[4%] left-[10%]">
                            <div>
                                <i class="fas fa-truck text-amber-400"></i>
                                <div class="text-amber-400 text-xs font-bold">
                                    <div>{{$tt('Commande')}}</div>
                                    <div>({{ qttFournisseur }})</div>
                                </div>
                            </div>
                        </div>
                        <div class="border-t-[2px] border-amber-400 absolute left-[0%] right-[0%] top-[0%]"></div>
                        <div class="border-r-[2px] border-amber-400 absolute left-[0%] top-[0%] bottom-[50%]"></div>
                        <i class="fas fa-caret-right text-xl text-amber-400 absolute top-[0%] right-0 -mt-[12px]"></i>
                    </template>
                </div>
                <div class="w-[80%] -mt-[50%]">
                    <!-- <div class="text-xs font-bold text-center text-emerald-500 py-2 bg-emerald-100 rounded mb-1">Configuration de la pièce</div> -->
                    <div class="border border-emerald-600 shadow rounded bg-emerald-50 p-2">
                        <div class="border-b border-emerald-600 pb-2 mb-2 font-bold text-sm"><i class="fas fa-euro mr-1"></i> {{ $tt('Tarif unitaire') }}</div>
                        <div class="grid grid-cols-3 border border-emerald-600 rounded mb-2">
                            <div class="text-center p-2 border-r border-emerald-600 text-emerald-800">
                                <div class="text-xs font-light">{{ $tt('Prix') }}<br />{{ $tt('Achat') }}</div>
                                <div class="text-lg font-bold">{{ formPiece.prix_ha }} €</div>
                            </div>
                            <div class="text-center p-2 border-r border-emerald-600 text-emerald-800">
                                <div class="text-xs font-light">{{ $tt('Remise') }}<br />{{ $tt('Fournisseur') }}</div>
                                <div class="text-lg font-bold">{{ formPiece.remise_fournisseur }} %</div>
                            </div>
                            <div class="text-center p-2 border-emerald-600 text-emerald-800">
                                <div class="text-xs font-light">{{ $tt('Prix') }}<br />{{ $tt('Achat Net') }}</div>
                                <div class="text-lg font-bold">{{ formPiece.prix_ha_net }} €</div>
                            </div>
                        </div>
                        <div :class="prixForceDefault ? '' : 'grid grid-cols-2'" class="mt-3">
                            <div @click="selectedPiece.pv_force > 0 ? $toast.error($tt('Prix forcé obligatoire')) : prixForce = 0" v-if="!prixForceDefault" class="border border-emerald-500 text-center text-sm p-2 cursor-pointer rounded-tl" :class="prixForce == 0 ? 'font-bold bg-emerald-400 text-emerald-800' : 'hover:bg-emerald-100 duration-200'">{{ $tt('Auto') }}</div>
                            <div @click="prixForce = 1" class="border border-emerald-500 text-center text-sm p-2 cursor-pointer" :class="(prixForce == 1 ? 'font-bold bg-emerald-400 text-emerald-800' : 'hover:bg-emerald-100 duration-200') + ' ' + (prixForceDefault ? 'rounded-t' : 'border-l-0 rounded-tr')">{{ prixForceDefault ? $tt('Prix de vente forcé'): $tt('Définir un prix') }}</div>
                        </div>
                        <div class="border border-t-0 border-emerald-500 p-3 bg-emerald-200 rounded rounded-t-none">
                            <template v-if="!prixForce">
                                <div class="grid xl:grid-cols-2 gap-4 items-center">
                                    <div class="text-center">
                                        <div class="font-bold text-xs">{{ $tt('Prix vente (HT)') }}</div>
                                        <div class="font-bold text-xs mt-1">{{ formPiece.end_tarif_ht }} €</div>
                                    </div>
                                    <div class="text-center">
                                        <div class="font-bold text-xs">{{ $tt('Prix vente (TTC)') }}</div>
                                        <div class="font-bold text-xs mt-1">{{ formPiece.end_tarif_ttc }} €</div>
                                    </div>
                                </div>
                            </template>
                            <template v-if="prixForce">
                                <div class="grid grid-cols-2 mb-2 items-center">
                                    <div class="font-bold text-xs">{{$tt('Prix vente HT')}}</div>
                                    <input-rule v-if="!prixForceDefault" v-model="formPiece.prix_force" type="price" custom="text-xs" />
                                    <div class="text-xs text-right" v-if="prixForceDefault">{{ formPiece.prix_force.toFixed(2) }} €</div>
                                </div>
                                <div class="grid grid-cols-2 mb-2 items-center">
                                    <div class="font-bold text-xs">{{$tt('Prix vente TTC')}}</div>
                                    <div class="text-xs text-right">{{ this.formPiece.prix_force ? parseFloat(this.formPiece.prix_force * 1.2).toFixed(2) : '0.00' }} €</div>
                                </div>
                            </template>
                        </div>
                    </div>

                    <!-- Button Confirm -->
                    <div class="mt-2">
                        <div class="text-sm mb-2">{{ $tt('Récapitulatif') }}</div>
                        <ul class="list-disc">
                            <li v-if="qttStock > 0" class="ml-4 mb-2 font-bold">{{ qttStock }} {{ qttStock > 1 ? $tt('pièces') : $tt('pièce') }} {{ $tt(' à consommer dans le stock') }}</li>
                            <li v-if="qttFournisseur > 0" class="ml-4 mb-2 font-bold">{{ qttFournisseur }} {{ qttFournisseur > 1 ? $tt('pièces') : $tt('pièce') }} {{ $tt('à commander chez') }} {{ selectedFournisseur?.nom }}</li>
                        </ul>
                        <div class="text-center mt-2 pt-2 border-t">
                            <button :disabled="loadingAddPiece" @click="command" class="px-4 py-2 bg-green-600 hover:bg-green-700 duration-200 text-white rounded font-bold text-sm">
                                <spinner :size="10" v-if="loadingAddPiece" />
                                <template v-if="!loadingAddPiece">
                                    <i class="fas fa-check mr-1"></i> {{ $tt('Confirmer') }}
                                </template>
                            </button>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>

        <modal name="modal-add-piece" height="auto" :scrollable="true">
            <div class="flex items-center justify-between bg-blue-100 px-4 py-1">
                <div class="text-xs font-light"><i class="fas fa-file-lines mr-1"></i> {{ $tt('Ajouter une pièce') }}</div>
                <button @click="$modal.hide('modal-add-piece')">
                    <i class="fas fa-times text-red-500 text-xl"></i>
                </button>
            </div>
            <div class="p-4 min-h-[400px] overflow-x-auto">
                <input-rule v-model="newPiece.ref" id="inp-new-piece-ref" type="text" :label="$tt('Référence')" :label-inline="false" class="mb-3" />
                <input-rule v-model="newPiece.design" id="inp-new-piece-design" type="text" :label="$tt('Désignation')" :label-inline="false" class="mb-3" />
                <input-rule v-model="newPiece.fournisseur" id="inp-new-piece-fournisseur" :options="{values: myFournisseurs, label: 'nom'}" type="vselect" :label="$tt('Nom du fournisseur')" :label-inline="false" class="mb-4" />
                <input-rule v-model="newPiece.prix_ha" id="inp-new-piece-prix_ha" type="price" :label="$tt('Prix d\'achat de la pièce')" :label-inline="false" class="mb-4" />
                <button :disabled="loadingAddNewPiece" @click="addPiece" class="px-4 py-2 bg-green-600 hover:bg-green-700 duration-200 rounded text-white text-xs mt-2">
                    <spinner v-if="loadingAddNewPiece" :size="10" />
                    <template v-if="!loadingAddNewPiece">
                        <i class="fas fa-check mr-1"></i> {{ $tt('Ajouter la pièce') }}
                    </template>
                </button>
            </div>
        </modal>

        <modal name="modal-add-fournisseur-commande" height="auto" :scrollable="true">
            <div class="flex items-center justify-between bg-blue-100 px-4 py-1">
                <div class="text-xs font-light"><i class="fas fa-file-lines mr-1"></i> {{ $tt('Choix du fournisseur') }}</div>
                <button @click="$modal.hide('modal-add-fournisseur-commande')">
                    <i class="fas fa-times text-red-500 text-xl"></i>
                </button>
            </div>
            <div class="p-4 min-h-[400px] overflow-x-auto">
                <input-rule v-model="newFournisseurForm.fournisseur" id="inp-new-four-fournisseur" :options="{values: myFournisseurs, label: 'nom'}" type="vselect" :label="$tt('Nom du fournisseur')" :label-inline="false" class="mb-4" />
                <input-rule v-model="newFournisseurForm.prix_ha" id="inp-new-four-prix_ha" type="price" :label="$tt('Prix d\'achat de la pièce')" :label-inline="false" class="mb-4" />
                <button @click="addNewFournisseur" class="bg-amber-200 hover:bg-amber-300 duration-200 px-4 py-2 rounded text-amber-800 font-bold text-xs"><i class="fas fa-plus mr-1"></i> {{ $tt('Ajouter') }}</button>
            </div>
        </modal>
    </div>
</template>

<script>
export default {
    props: {
        idDossier: {},
        refDefault: {},
        fournisseurDefault: {},
        qttCommandeDefault: {},
        fromStock: {default: false},
        stockId: {}
    },
    watch: {
        formSearch: {
            deep: true,
            handler() {
                this.init()
                this.searchPieces()
            }
        },

        selectedFournisseur: {
            deep: true,
            handler() {
                this.setExpensiveAmount()
            }
        },

        selectedStock: {
            deep: true,
            handler() {
                this.setExpensiveAmount()
            }
        },
    },
    data() {
        return {
            coeff: 0,

            pieces: [],
            selectedPiece: null,
            selectedTypePiece: null,
            selectedPieceRef: null,
            similars: [],

            equivalences: [],

            stocks: [],
            selectedStock: null,
            qttStock: 0,
            loadingStock: false,

            fournisseurs: [],
            selectedFournisseur: null,
            qttFournisseur: 0,

            myFournisseurs: [],
            newFournisseurForm: {},

            formPiece: {},
            configPiece: {},
            loadingAddNewPiece: false,

            // formPieceTimer: null,
            loadingGetConfig: false,
            prixForce: false,
            loadingAddPiece: false,

            formSearch: {},
            timerSearch: null,

            newPiece: {},

            lines: {
                toStock: null,
                toCommande: null,
            },
        }
    },

    computed: {
        prixForceDefault() {
            if (this.selectedPiece) {
                return this.selectedPiece.pv_force > 0
            }
            return false
        }
    },

    methods: {
        init() {
            this.selectedPiece = null
            this.selectedPieceRef = null
            this.selectedTypePiece = null
            this.selectedStock = null
            this.selectedFournisseur = null
            this.prixForce = false
            this.similars = []
            this.formPiece = {}
        },
        async getMyFournisseurs() {
            let response = await this.$request.post('reparateur.get-fournisseurs')
            this.myFournisseurs = response.data.posts.post
        },
        async getCoeff() {
            let response = await this.$request.post('technicien.get-coeff-amount', {
                montant: this.formPiece.prix_ha
            })
            this.coeff = response.data.posts.post
            this.formPiece.coeff = this.coeff.value
            this.calculTarifs()
        },
        async searchPieces() {
            clearTimeout(this.timerSearch)
            this.timerSearch = setTimeout(async () => {
                let endData = {}
                if (this.formSearch.ref) {
                    endData.ref = this.formSearch.ref
                }
                if (this.formSearch.design) {
                    endData.design = this.formSearch.design
                }
                let response = await this.$request.post('technicien.search-piece', endData)
                this.pieces = response.data.posts.post

                if (this.refDefault && this.pieces.find((item) => item.ref.toUpperCase() == this.refDefault.toUpperCase())) {
                    this.selectPiece(this.pieces.find((item) => item.ref.toUpperCase() == this.refDefault.toUpperCase()), 'referentiel')
                }
            }, 600)
        },

        async findSimilarPieces() {
            if (this.selectedPiece) {                
                this.similars = []
                let response = await this.$request.post('technicien.search-similar-piece', {
                    id_piece: this.selectedPiece.id
                })
                this.similars = response.data.posts.post
            }
        },

        async selectPiece(piece, type) {
            this.selectedStock = null
            this.prixForce = 0
            this.selectedFournisseur = null

            if (this.selectedPiece && this.selectedPiece.ref == piece.ref) {
                this.selectedPiece = null
                this.selectedPieceRef = null
                this.selectedTypePiece = null
            } else {
                this.selectedPiece = piece
                this.selectedTypePiece = type
                if (this.selectedPiece.pv_force > 0) {
                    this.prixForce = 1
                    this.formPiece.prix_force = this.selectedPiece.pv_force
                }
                // this.getConfigPiece()
                await this.getFournisseurs()
                await this.getStocks()
                if (type == 'referentiel') {                    
                    this.selectedPieceRef = piece
                    await this.findSimilarPieces()
                }
            }
        },

        async getFournisseurs() {            
            this.fournisseurs = []
            this.qttFournisseur = 0
            let response = await this.$request.post('technicien.get-fournisseurs-piece', {
                ref: this.selectedPiece.ref,
                stock: this.fromStock ? 1 : 0
            })
            this.fournisseurs = response.data.posts.post
            
            if (this.fournisseurDefault) {
                if (this.fournisseurs.find((item) => item.id == this.fournisseurDefault.id)) {
                    this.selectFournisseur(this.fournisseurs.find((item) => item.id == this.fournisseurDefault.id))
                }
            }
            this.$forceUpdate()            
        },

        async getStocks() {
            this.qttStock = 0
            this.stocks = []
            this.loadingStock = true
            let response = await this.$request.post('technicien.get-stocks-piece', {
                ref: this.selectedPiece.ref
            })
            this.loadingStock = false
            this.stocks = response.data.posts.post
        },

        async selectStock(stock) {
            if (this.fromStock) {
                this.$toast.warning(this.$tt('Vous ne pouvez pas séléctionner une pièce du stock'))
                return
            }
            if (this.selectedStock && this.selectedStock.id == stock.id) {
                this.selectedStock = null
                this.qttStock = 0
                this.formPiece.prix_ha = 0
            } else {
                this.selectedStock = stock
                if (stock.catalogue.pv_force > 0) {
                    this.prixForce = 1
                    this.formPiece.prix_force = stock.catalogue.pv_force
                    this.selectedPiece.pv_force = stock.catalogue.pv_force
                } else {
                    this.prixForce = 0
                    this.formPiece.prix_force = '0.00'
                    this.selectedPiece.pv_force = '0.00'
                }
                
                this.calculTarifs()
                // let response = await this.$request.post('technicien.get-piece-catalogue', {
                //     ref: this.selectedStock.ref,
                //     id_four: this.selectedStock.id_fournisseur
                // })
                // console.log(response.data.posts.post);
            }
        },

        selectFournisseur(fournisseur) {
            if (this.selectedFournisseur && this.selectedFournisseur.id == fournisseur.id && this.selectedFournisseur.prix_moyen == fournisseur.prix_moyen) {
                this.selectedFournisseur = null
                this.qttFournisseur = 0
                this.configPiece = {}
            } else {
                this.selectedFournisseur = fournisseur
                this.getPieceAmount()
            }

            if (this.qttCommandeDefault) {
                this.qttFournisseur = this.qttCommandeDefault
                this.setExpensiveAmount()
            }
        },

        async getPieceAmount() {
            let response = await this.$request.post('technicien.get-config-piece-amount', {
                ref: this.selectedPiece.ref,
                id_fournisseur: this.selectedFournisseur.id
            })
            this.configPiece = response.data.posts.post
            this.setExpensiveAmount()
            this.calculTarifs()
        },

        setExpensiveAmount() {              
            let selectFournisseur = () => {
                this.formPiece.prix_ha = this.selectedFournisseur.prix_moyen
                this.formPiece.prix_ha_net = (this.selectedFournisseur.prix_moyen - (this.selectedFournisseur.prix_moyen * this.configPiece.remise_fournisseur / 100)).toFixed(2)
                this.formPiece.remise_fournisseur = this.configPiece.remise_fournisseur
            }
            let selectStock = () => {
                console.log(this.selectedStock);
                
                this.formPiece.prix_ha = this.selectedStock.prix_ha
                this.formPiece.prix_ha_net = this.selectedStock.prix_ha_net.toFixed(2)
                this.formPiece.remise_fournisseur = this.selectedStock.remise_fournisseur
            }
            
            // Only fournisseur
            if (this.selectedFournisseur && parseFloat(this.qttFournisseur) > 0 && (!this.selectedStock || this.qttStock <= 0)) {
                selectFournisseur()
            }

            // Only stock
            if ((!this.selectedFournisseur || this.qttFournisseur <= 0) && this.selectedStock && parseFloat(this.qttStock) > 0) {
                selectStock()
            }
            
            if (this.selectedFournisseur && parseFloat(this.qttFournisseur) > 0 && this.selectedStock && parseFloat(this.qttStock) > 0) {
                if (this.selectedStock.prix_ha > this.selectedFournisseur.prix_moyen) {
                    selectStock()
                } else {
                    selectFournisseur()
                }
            }
            
            this.getCoeff()
        },

        async command() {
            if (this.qttStock < 0) {
                this.$toast.error(this.$tt('La quantité du stock doit être supérieur à 0'))
                return
            }
            if (this.qttFournisseur < 0) {
                this.$toast.error(this.$tt('La quantité de la commande doit être supérieur à 0'))
                return
            }
            if (this.selectedStock && this.qttStock > this.selectedStock.qtt) {
                this.$toast.error(this.$tt("Vous n'avez pas assez de pièces en stock"))
                return
            }
            let endData = {
                stock: {
                    id_stock: this.selectedStock ? this.selectedStock.id : 0,
                    qtt: this.qttStock
                },
                commande: {
                    id_fournisseur: this.selectedFournisseur ? this.selectedFournisseur.id : 0,
                    qtt: this.qttFournisseur
                },
            }

            this.loadingAddPiece = true
            let response = await this.$request.post('technicien.commande-pieces', {
                id_dossier: this.idDossier ? this.idDossier : 0,
                commande: JSON.stringify(endData),
                designation: this.selectedPiece.design,
                ref: this.selectedPiece.ref,
                prix_ha: this.formPiece.prix_ha,
                prix_ha_net: this.formPiece.prix_ha_net,
                prix_vente: this.prixForce ? this.formPiece.prix_force : this.formPiece.end_tarif_ht,
                prix_vente_net: this.prixForce ? (this.formPiece.prix_force * 1.20) : this.formPiece.end_tarif_ttc,
                remise: this.formPiece.remise_fournisseur,
                coeff: this.formPiece.coeff,
                prix_force: this.prixForce,
                from_stock: this.fromStock ? 1 : 0,
                id_stock: this.stockId ? this.stockId : 0
            })
            this.loadingAddPiece = false

            if (response.data.posts.post == 1) {
                this.$modal.hide('modal-stock-piece')
                this.init()
                this.formSearch = {}
                this.$emit('added')
            }
        },

        calculTarifs() {            
            if (this.formPiece) {
                if (this.prixForce == 1) {
                    this.formPiece.end_tarif_ht = this.formPiece.prix_force
                }                
                
                if (!this.formPiece.coeff || this.formPiece.coeff == 0) {                    
                    this.formPiece.end_tarif_ht = this.formPiece.prix_ha_net
                } else {                    
                    this.formPiece.end_tarif_ht =  this.formPiece.prix_ha_net * this.formPiece.coeff
                }
            }

            this.formPiece.end_tarif_ttc = this.formPiece.end_tarif_ht * 1.20
            
            this.formPiece.end_tarif_ht = parseFloat(this.formPiece.end_tarif_ht).toFixed(2)
            this.formPiece.end_tarif_ttc = parseFloat(this.formPiece.end_tarif_ttc).toFixed(2)
            this.$forceUpdate()
        },

        addNewFournisseur() {
            if (!this.$validation.check([
                {value: this.newFournisseurForm.fournisseur, rules: 'required', id: 'inp-new-four-fournisseur'},
                {value: this.newFournisseurForm.prix_ha, rules: 'required', id: 'inp-new-four-prix_ha'},
            ])) {return}

            if (this.newFournisseurForm.prix_ha <= 0) {
                this.$toast.error(this.$tt("Le prix d'achat doit être supérieur à 0.00") + " €")
                return
            }

            this.fournisseurs.push({
                id: this.newFournisseurForm.fournisseur.id,
                nom: this.newFournisseurForm.fournisseur.nom,
                prix_moyen: this.newFournisseurForm.prix_ha
            })

            this.$forceUpdate()

            this.selectFournisseur(this.fournisseurs[this.fournisseurs.length - 1])

            this.newFournisseurForm = {}

            this.$modal.hide('modal-add-fournisseur-commande')
        },

        openStock(stock) {
            this.stocks.forEach((item) => {
                item.open = false
            })
            stock.open = !stock.open
        },

        async addPiece() {
            if (!this.$validation.check([
                {value: this.newPiece.ref, rules: 'required', id: 'inp-new-piece-ref'},
                {value: this.newPiece.design, rules: 'required', id: 'inp-new-piece-design'},
                {value: this.newPiece.fournisseur, rules: 'required', id: 'inp-new-piece-fournisseur'},
                {value: this.newPiece.prix_ha, rules: 'required', id: 'inp-new-piece-prix_ha'},
            ])) {return}

            if (this.newPiece.prix_ha <= 0) {
                this.$toast.error(this.$tt("Le prix d'achat doit être supérieur à 0.00") + " €")
                return
            }

            this.newPiece.id = 0
            let form = JSON.parse(JSON.stringify(this.newPiece))
            
            let piece = {
                id: 0,
                ref: form.ref,
                design: form.design
            }
            this.loadingAddNewPiece = true
            this.pieces.push(piece)
            await this.selectPiece(piece, 'referentiel')
            this.newPiece = {}
            this.loadingAddNewPiece = false

            this.newFournisseurForm = {
                fournisseur: form.fournisseur,
                prix_ha: form.prix_ha
            }
            this.addNewFournisseur()

            this.$modal.hide('modal-add-piece')
        },

        displayModalAddPiece() {
            this.newPiece = {
                ref: this.formSearch.ref ? this.formSearch.ref : null,
                design: this.formSearch.design ? this.formSearch.design : null,
                fournisseur: null,
                prix_ha: null
            }
            
            this.$modal.show('modal-add-piece')
        }
    },
    mounted () {
        if (!this.fromStock && !this.idDossier) {
            this.$toast.error(this.$tt('Erreur: id_dossier obligatoire'))
            return
        }
        this.init()
        this.getMyFournisseurs()
        if (this.refDefault) {
            this.formSearch = {
                ref: this.refDefault,
                design: null
            }
        }
    },
}
</script>

<style>
.table-pieces table, .table-pieces tr td {
    border: 1px solid #E4E4E4;
}
.table-pieces th, .table-pieces td {
    /* width: 120px; */
    white-space: nowrap; /* Empêche le texte de s'étendre sur plusieurs lignes */
    overflow: hidden; /* Cache le débordement du contenu */
    border: 1px solid #E4E4E4;
    word-wrap: break-word 
}
.table-pieces tbody {
    display: block;
    overflow-y: scroll;
    overflow-x: hidden;
}
.table-pieces thead, .table-pieces tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;/* even columns width , fix width of table too*/
}
.table-pieces thead {
    width: calc(100% - 1em)
}
</style>