<template>
    <div>
        <input
        :placeholder="placeholder"
        v-model="inputValue"
        @focusin="goFocus"
        @focusout="unFocus"
        @input="onInput"
        @click.stop.prevent
        type="text"
        :disabled="disabled"
        class="text-sm rounded-0 block w-full p-1.5 focus:outline-none focus:border-gray-400 border border-gray-300 text-gray-900"
        :class="(labelInline ? 'border-0 border-b text-right' : 'rounded-lg bg-gray-50') + ' ' + custom"
        />
    </div>
</template>

<script>
export default {
    props: {
        value: {},
        labelInline: {},
        disabled: {},
        displayCurrency: {default: true},
        custom: {},
        placeholder: {}
    },

    watch: {
        value() {
            this.inputValue = this.value
            if (this.inputValue && !this.isFocus) {
                this.inputValue = parseFloat(this.inputValue).toFixed(2)
                if (this.displayCurrency && this.inputValue[this.inputValue.length - 1] != '€') {
                    this.inputValue += '€'
                }
            }
        }
    },

    data() {
        return {
            inputValue: null,
            isFocus: false,
        }
    },

    computed: {
        formatedNumber() {
            let price = this.inputValue
            if (price[price.length - 1] == '.') {
                price = price.slice(0, -1)
            }

            price = String(parseFloat(price).toFixed(2))

            return price
        }
    },

    methods: {
        onInput(value) {
            if (this.inputValue) {
                this.inputValue = this.inputValue.replace(/[^\d,.-]/g,'')
                this.inputValue = this.inputValue.replace(',', '.')

                let lastChar = value.data == ',' ? '.' : value.data
                let nbComa = (this.inputValue.match(/\./g) || []).length

                if (lastChar == '.' && nbComa > 1) {
                    this.inputValue = this.inputValue.split('.')[0] + '.' + this.inputValue.split('.')[1].slice(0, 2)
                }

                if (nbComa == 1 && this.inputValue.split('.')[1].length > 2) {
                    this.inputValue = this.inputValue.slice(0, -1)
                }

                this.$emit('input', this.inputValue.replace('€', ''))
            } else {
                this.$emit('input', null)
            }
        },

        goFocus() {
            this.isFocus = true
            if (this.inputValue) {
                this.inputValue = this.inputValue.replace('€', '')
            }

        },

        unFocus() {            
            this.isFocus = false
            if (this.inputValue) {
                if (this.inputValue[this.inputValue.length - 1] == '.') {
                    this.inputValue = this.inputValue.slice(0, -1)
                }

                this.inputValue = String(parseFloat(this.inputValue).toFixed(2))

                if (this.displayCurrency) {
                    this.inputValue += '€'
                }
                this.$emit('input', this.inputValue.replace('€', ''))
                this.$emit('focusout')
            } else {
                this.$emit('input', null)
            }
        },
    },

    mounted () {
        if (this.value) {
            this.inputValue = this.value
        }
        this.unFocus()
    },
    
}
</script>

<style lang="scss" scoped>

</style>
