<template>
    <div>
        <app-template>
            <template v-if="displayStockPiece">
                <stock-piece
                class="bg-white h-screen"
                :id-dossier="this.$route.query.id_dossier"
                @added="emitEvent"
                :ref-default="this.$route.query.ref"
                />
            </template>
        </app-template>
    </div>
</template>

<script>
import StockPiece from './Components/StockPiece.vue'
export default {
    components: {
        StockPiece,
    },
    data() {
        return {
            displayStockPiece: false
        }
    },
    methods: {
        emitEvent() {
            window.parent.postMessage({from: 'commande', type: 'added', meta: null}, "*");
        }
    },
    mounted () {
        if (!this.$route.query.id_dossier) {
            this.$toast.error(this.$tt('id_dossier obligatoire'))
        } else {
            this.displayStockPiece = true
        }
    },
}
</script>

<style lang="scss" scoped>

</style>