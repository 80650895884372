<template>
    <div>
        <!-- Modal add product manually -->
        <modal name="modal-add-product" height="auto" width="80%" :scrollable="true">
            <div class="p-10 min-h-[500px]">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-2xl">
                    <h2 class="font-bold">
                        {{$tt('Ajouter un produit')}}
                    </h2>
                    <button @click="$modal.hide('modal-add-product')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <div class="mb-4">
                        <div class="flex items-end gap-2">
                            <!-- <input-rule class="grow" type="vselect" v-model="newProductForm['id_categorie']" id="categorie" :options="{values: allCategories, label: 'fr', key: 'id_categorie'}" :label="$tt('Catégorie')" :label-inline="false" @input="getProcsOnCateg" /> -->
                            <input-rule class="grow" type="vselect" v-model="newProductForm['id_categorie']" id="categorie" :options="{values: allCategories, label: 'fr', key: 'id_categorie'}" :label="$tt('Catégorie')" :label-inline="false" />
                            <button @click="$modal.show('modal-add-categ')" class="px-4 py-2.5 rounded text-xs bg-slate-100 hover:bg-slate-200 duration-200"><i class="fas fa-plus mr-0.5"></i> {{$tt('Nouvelle catégorie')}}</button>
                        </div>
                    </div>
                    <template v-if="newProductForm && newProductForm['id_categorie']">
                        <div class="grid grid-cols-2 gap-4 mb-4">
                            <div class="border p-4 rounded border-gray-300 bg-slate-50">
                                <div class="text-xs font-bold text-gray-900 border-b pb-3 mb-3 border-gray-300">{{$tt('Appareil')}}</div>
                                <div class="grid grid-cols-2 gap-4 mb-4">
                                    <div class="-mt-1.5">
                                        <label class="text-xs font-bold" :class="marqueError ? 'text-red-500' : 'text-gray-900'">{{$tt('Marque')}}</label>
    
                                        <v-select
                                        v-model="marqueInp"
                                        :options="marques"
                                        @search="(search, loading) => { 
                                            $forceUpdate()
                                        }"
                                        class="mt-1"
                                        :label="$tt('Marque')"
                                        ref="inpMarque"
                                        id="marque"
                                        >
                                            <template v-slot:no-options="{search}">
                                                <div class="hover:bg-blue-400 hover:text-white duration-200 text-left px-5 cursor-pointer py-1.5" @click="$refs.inpMarque.open = false; marques.push({Marque: search}); marqueInp = {Marque: search}">
                                                    <i class="fas fa-plus mr-0.5 "></i> <b>{{ search }}</b>
                                                </div>
                                            </template>
                                        </v-select>
                                        <ul v-if="marqueError" class="list-disc text-red-500 ml-4 mt-2 text-xs">
                                            <li>{{ marqueError }}</li>
                                        </ul>
                                    </div>
                                    <!-- <input-rule v-model="newProductForm['Marque']" id="marque" type="text" label="Marque" :label-inline="false" custom="bg-white" /> -->
                                    <input-rule v-model="newProductForm['Modele']" id="modele" type="text" :label="$tt('Modèle')" :label-inline="false" custom="bg-white" />
                                </div>
                                <div class="grid grid-cols-2 gap-4 mb-4">
                                    <input-rule v-model="newProductForm['Ean']" id="ean" type="text" :label="$tt('Ean')" :label-inline="false" custom="bg-white" />
                                    <input-rule v-model="newProductForm['ref_commercial']" type="text" :label="$tt('Ref. Commerciale')" :label-inline="false" custom="bg-white" />
                                </div>
                                <div class="mb-4">
                                    <input-rule v-model="newProductForm['Libelle']" id="libelle" type="text" :label="$tt('Libellé')" :label-inline="false" custom="bg-white" />
                                </div>
                                <div class="grid grid-cols-2 gap-4 mb-4">
                                    <!-- <input-rule type="select" v-model="newProductForm['deee']" :options="{values: [], label: 'fr', key: 'id_categorie'}" label="DEEE" :label-inline="false" custom="bg-white" /> -->
                                    <input-rule v-model="newProductForm['poids']" type="text" :label="$tt('Poids (kg)')" :label-inline="false" custom="bg-white" id="poids" />
                                </div>
                            </div>
                            <div class="border p-4 rounded border-gray-300 bg-slate-50">
                                <div class="text-xs font-bold text-gray-900 border-b pb-3 mb-3 border-gray-300">{{$tt('Documents')}}</div>
                                <div class="mt-2 relative overflow-x-auto">
                                    <table class="w-full text-sm text-left rtl:text-right text-gray-500 border">
                                        <thead class="text-xs text-gray-900 uppercase bg-gray-50">
                                            <tr>
                                                <th scope="col" class="px-6 py-1.5">
                                                    {{$tt('Type')}}
                                                </th>
                                                <th scope="col" class="px-6 py-1.5">
                                                    {{$tt('Fichier')}}
                                                </th>
                                                <th scope="col" class="px-6 py-1.5">
                                                    {{$tt('Actions')}}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="bg-white border-b">
                                                <td class="px-6 py-1.5">
                                                    {{$tt('Notice')}}
                                                </td>
                                                <td class="px-6 py-1.5">
                                                    <!-- <a href="#" class="text-blue-500 hover:underline font-light"><i class="fas fa-link mr-0.5"></i> Voir le fichier</a> -->
                                                    <input-rule @input="(data) => {if (data) {newProductForm.url_notice = data.url; $forceUpdate()}}" type="file" />
                                                </td>
                                                <td class="px-6 py-1.5">
                                                    <div v-if="newProductForm.url_notice && newProductForm.url_notice != '-1'" class="flex gap-1">
                                                        <a target="_blank" :href="newProductForm.url_notice" class="px-2 py-1 bg-blue-500 hover:bg-blue-600 duration-200 text-xs text-white rounded"><i class="fas fa-eye"></i></a>
                                                        <button @click="newProductForm.url_notice = '-1'; $forceUpdate()" class="px-2 py-1 bg-red-500 hover:bg-red-600 duration-200 text-xs text-white rounded"><i class="fas fa-trash"></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="bg-white border-b">
                                                <td class="px-6 py-1.5">
                                                    {{$tt('Photo du produit')}}
                                                </td>
                                                <td class="px-6 py-1.5 text-xs col-span-2">
                                                    <input-rule @input="(data) => {if (data) {newProductForm.url_photo = data.url; $forceUpdate()}}" type="file" />
                                                </td>
                                                <td class="px-6 py-1.5">
                                                    <div v-if="newProductForm.url_photo && newProductForm.url_photo != '-1'" class="flex gap-1">
                                                        <a target="_blank" :href="newProductForm.url_photo" class="px-2 py-1 bg-blue-500 hover:bg-blue-600 duration-200 text-xs text-white rounded"><i class="fas fa-eye"></i></a>
                                                        <button @click="newProductForm.url_photo = '-1'; $forceUpdate()" class="px-2 py-1 bg-red-500 hover:bg-red-600 duration-200 text-xs text-white rounded"><i class="fas fa-trash"></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="bg-white border-b">
                                                <td class="px-6 py-1.5">
                                                    {{$tt('Vue éclatée')}}
                                                </td>
                                                <td class="px-6 py-1.5 text-xs col-span-2">
                                                    <input-rule @input="(data) => {if (data) {newProductForm.url_vue = data.url; $forceUpdate()}}" type="file" />
                                                </td>
                                                <td class="px-6 py-1.5">
                                                    <div v-if="newProductForm.url_vue && newProductForm.url_vue != '-1'" class="flex gap-1">
                                                        <a target="_blank" :href="newProductForm.url_vue" class="px-2 py-1 bg-blue-500 hover:bg-blue-600 duration-200 text-xs text-white rounded"><i class="fas fa-eye"></i></a>
                                                        <button @click="newProductForm.url_vue = '-1'; $forceUpdate()" class="px-2 py-1 bg-red-500 hover:bg-red-600 duration-200 text-xs text-white rounded"><i class="fas fa-trash"></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="border p-4 rounded border-gray-300 bg-slate-50">
                            <div class="text-xs font-bold text-gray-900 border-b pb-3 mb-3 border-gray-300">{{$tt('Appliquer les procédures')}}</div>
                            <div v-if="loadingGetProcs" class="p-4">
                                <spinner :size="10" />
                            </div>
                            <div class="bg-white" v-if="categProcs[0]">
                                <table-procedure
                                class="mt-2"
                                :can-create="false"
                                :read-only="true"
                                :procedures="categProcs[0].procedures"
                                :activation="true"
                                @on-change="getDisabledProcs"
                                />
                            </div>
                        </div> -->
                        <div class="border-t-2 mt-6 pt-3">
                            <button :disabled="loadingAddApp" @click="addProduct" class="px-4 py-2 rounded text-sm bg-green-200 hover:bg-green-300 cursor-pointer duration-200">
                                <spinner v-if="loadingAddApp" :size="10" />
                                <template v-if="!loadingAddApp">
                                    <i class="fas fa-check mr-1"></i> {{$tt('Enregistrer')}}
                                </template>
                            </button>
                        </div>
                    </template>
                </div>
            </div>
        </modal>

        <modal name="modal-add-categ" height="auto" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-2xl">
                    <h2 class="font-bold">
                        {{$tt('Ajouter une catégorie')}}
                    </h2>
                    <button @click="$modal.hide('modal-add-categ')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <input-rule v-model="newCategName" type="text" :label-inline="false" :label="$tt('Nom de la catégorie')" id="new-categ-name" />
                    <button :disabled="addCategLoader" @click="addNewCateg" class="mt-2 px-4 py-2 text-sm bg-green-500 text-white rounded">
                        <spinner v-if="addCategLoader" :size="10" />
                        <template v-if="!addCategLoader">
                            {{$tt('Ajouter')}} <i class="fas fa-plus ml-1"></i>
                        </template>
                    </button>
                </div>
            </div>
        </modal>

        <!-- Modal import product list -->
        <modal name="modal-import-list" height="auto" width="90%" :scrollable="true" :click-to-close="false">
            <div class="p-10">
                <div>
                    <import-product-file @refresh-categs="getAllCategories" />
                </div>
            </div>
        </modal>

        <!-- Modal create pattern -->
        <modal name="modal-edit-add-proc-app-list" height="auto" width="80%" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between border-b pb-3 mb-3">
                    <h2 class="text-xl font-bold">{{$tt('Ajouter une procédure')}}</h2>
                    <button @click="$modal.hide('modal-edit-add-proc-app-list')">
                        <i class="fas fa-times text-red-500 text-lg"></i>
                    </button>
                </div>
                <div>
                    <form-procedure @update="getPatterns(); $modal.hide('modal-edit-add-proc-app-list')" :procedure="null" />
                </div>
            </div>
        </modal>

        <!-- Modal set proc on existant app -->
        <modal name="modal-view-proc-app" height="auto" width="70%" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-2xl">
                    <h2 class="font-bold">
                        {{$tt('Procédures paramétrées sur l\'appareil')}}
                    </h2>
                    <button @click="$modal.hide('modal-view-proc-app')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <template v-if="selectedProduct && selectedProduct.procedures">
                    <template v-if="selectedProduct.procedures.length <= 0">{{$tt('Aucune procédure')}}</template>
                    <table-procedure
                    v-if="selectedProduct.procedures.length > 0"
                    class="bg-white border"
                    :can-create="false"
                    :procedures="selectedProduct.procedures"
                    :read-only="true"
                    :display-centrale="true"
                    />
                </template>
                <template v-else>
                    <spinner :size="10" />
                </template>
            </div>
        </modal>

        <!-- Modal edit alert product -->
        <modal name="modal-edit-alert-product" height="auto" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-2xl">
                    <h2 class="font-bold">
                        {{$tt('Alerte produit')}}
                    </h2>
                    <button @click="$modal.hide('modal-edit-alert-product')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <input-rule v-model="newAlertMsg" type="richtext" :label="$tt('Message')" :label-inline="false" />
                    <input-rule v-model="alertMsgCateg" class="mt-4" type="pcheck" :label="$tt('Appliquer à tout les appareils de la même catégorie')" :label-inline="false" />
                    <button @click="saveAlertMsg" class="mt-4 px-4 py-2 rounded text-sm text-white bg-green-500 hover:bg-green-600 duration-200">
                        {{$tt('Enregister')}} <i class="fas fa-check ml-1"></i>
                    </button>
                </div>
            </div>
        </modal>

        <!-- Template -->
        <app-template>
            <spinner :size="14" v-if="loadingGetApps" />
            <template v-if="!loadingGetApps">
                <div class="bg-white p-7 rounded border shadow-sm mb-16">
                    <div class="bg-slate-50 p-4 rounded">
                        <h2 class="font-semibold">{{$tt('Ajouter des produits')}}</h2>
                        <div class="flex gap-2 items-center">
                            <button @click="selectedConfigs = {}; $modal.show('modal-add-product')" class="px-4 py-2 text-sm mt-2 bg-slate-200 hover:bg-slate-300 duration-200 rounded"><i class="fas fa-plus mr-1"></i> {{$tt('Ajouter un produit')}}</button>
                            <template v-if="client">
                                <button v-if="client.import_app_api == 0" @click="selectedConfigs = {}; $modal.show('modal-import-list')" class="px-4 py-2 text-sm mt-2 bg-slate-200 hover:bg-slate-300 duration-200 rounded"><i class="fas fa-file-import mr-1"></i> {{$tt('Import par fichier')}}</button>
                                <span v-if="client.import_app_api == 1" class="px-4 py-2 text-sm mt-2 bg-green-200 duration-200 rounded"><i class="fas fa-plug mr-1"></i> {{$tt('Vos produits sont automatiquements importés par API / EDI')}}</span>
                            </template>
                        </div>
                    </div>
                    <div class="p-4" v-if="!marques">
                        <spinner :size="10" />
                    </div>
                    <div class="grid grid-cols-3 gap-4 mt-4" v-if="marques && marques.length > 0">
                        <div>
                            <input-rule @input="getCategories(); getProducts()" type="vselect" v-model="selectedMarque" :options="{values: marques, label: 'Marque'}" :label="$tt('Marque')" :label-inline="false" />
                        </div>
                        <div>
                            <input-rule @input="getProducts()" type="vselect" v-model="selectedCategorie" :options="{values: categories, label: 'fr'}" :label="$tt('Catégorie')" :label-inline="false" />
                        </div>
                        <div>
                            <input-rule @input="getProducts()" v-model="searchEan" type="text" :label="$tt('EAN / Code article')" :label-inline="false" custom="bg-white rounded-sm border-gray-300" />
                        </div>
                    </div>
                    <div class="mt-4 bg-slate-100 rounded p-4" v-if="selectedCategorie && (!products || products.length <= 0)">
                        <i class="fas fa-exclamation-triangle mr-1"></i> {{$tt('Aucun produit')}}
                    </div>
                    <div v-if="products" class="mt-4">
                        <div class="mb-1 text-xs font-bold text-gray-900">{{$tt('Produits')}}</div>
                        <div class="border border-gray-300 rounded">
                            <div class="grid grid-cols-3 gap-2">
                                <div class="border-r p-4">
                                    <div class="max-h-[80vh] overflow-y-auto">
                                        <div class="border-b p-2 hover:bg-slate-100 duration-200 cursor-pointer" :class="selectedProduct && selectedProduct.id == product.id ? 'bg-slate-100' : ''" v-for="(product, productIndex) in products" :key="'product-' + productIndex" @click="selectProduct(product)">
                                            <div class="font-bold text-sm">{{ product['Libelle'] }}</div>
                                            <div class="text-sm font-light text-xs">{{ product['Ean'] }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-span-2 p-4">
                                    <div v-if="!selectedProduct" class="my-6 text-center text-slate-400 text-xl font-bold">
                                        <i class="fa-solid fa-computer-mouse"></i>
                                        <div>{{$tt('Choisir un produit')}}</div>
                                        <div class="text-sm font-light">{{$tt('Parmis la liste ci-contre')}}</div>
                                    </div>
                                    <div v-if="selectedProduct">
                                        <product-form
                                        :product="selectedProduct"
                                        :all-categories="allCategories"
                                        :marques="marques"
                                        @submit="() => {getMarques()}"
                                        @display-proc="() => {getProcOnApp(); $modal.show('modal-view-proc-app')}"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </app-template>
    </div>
</template>

<script>
import ApiService from '../../services/api.service';
import FormProcedure from '../Procedure/Components/FormProcedure.vue';
import TableProcedure from '../Procedure/Components/TableProcedure.vue';
import ImportProductFile from './Components/ImportProductFile.vue';
import ProductForm from './Components/ProductForm.vue';

export default {
    components: {
        FormProcedure,
        ImportProductFile,
        ProductForm,
        TableProcedure,
    },
    data() {
        return {
            marques: null,
            selectedMarque: null,
            allCategories: null,
            categories: null,
            selectedCategorie: null,
            products: null,
            selectedProduct: null,
            searchProducts: null,
            newImage: null,
            searchEan: null,
            timerSearch: null,
            newProductForm: {},
            marqueInp: {Marque: null},
            marqueError: null,
            newCategName: null,
            addCategLoader: false,

            procedures: null,
            allProcedures: [],
            selectedProcs: {},
            selectedConfigs: {},
            // categProcs: [],
            // loadingGetProcs: false,
            importProcsExceptions: [],

            applyProcs: true,
            
            loadingGetApps: false,
            loadingAddApp: false,
            csvFile: null,
            importationResults: [],
            importationResultsErrors: [],
            loadingUploadCsv: false,
            selectedTabCsvApp: 'errors',

            newAlertMsg: null,
            alertMsgCateg: false,

            selectedSpace: 'magasin',

            importationResultsProcedure: [],
            importationResultsProcedureErrors: [],
            enseignes: [],

            client: null,
        }
    },
    filters: {
        typeGarantieLabel(label) {
            let labels = {
                'garantie_marque': 'Garantie Marque',
                'garantie_distributeur': 'Garantie Distributeur',
                'garantie_pms': 'Garantie PMS',
                'garantie_comp': 'Garantie Complémentaire',
                'hors_garantie': 'Hors garantie',
            }
            return labels[label]
        }
    },
    methods: {
        async getClient() {
            let response = await this.$request.post('client.account')
            if (response.data.posts.post) {
                this.client = response.data.posts.post
            }
        },

        async getEnseignes() {
            let response = await this.$request.post('grossiste.get-liaisons')
            this.enseignes = response.data.posts.post
        },

        async getMarques() {
            let response = await this.$request.post('A5141getmarquefromgrossiste', {
                grossiste: ApiService.user().account.id_entite
            }, 'districloud_no_action')
            this.marques = response.data.posts.post
        },

        async getAllCategories() {
            let response = await this.$request.post('procedures.get-categs')
            this.allCategories = response.data.posts.post
        },

        async getCategories() {
            let response = await this.$request.post('backoffice', {
                operation: 'getcategbymarqueproc',
                type_entite: ApiService.user().account.type_entite,
                marque: this.selectedMarque ? this.selectedMarque['Marque'] : ''
            }, 'districloud_action')
            this.categories = null
            this.selectedCategorie = null
            this.products = null
            this.categories = response.data.posts.post.map((item) => {
                if (item.id_categorie == 0) {
                    item.fr = "Aucune catégorie"
                }
                return item
            })
        },

        async getProducts(defaultEan = null, selectFirst = false) {
            if (!this.selectedMarque && !this.selectedCategorie && !this.searchEan && !defaultEan) {
                return
            }
            this.timerSearch = setTimeout(async () => {
                let response = await this.$request.post('backoffice', {
                    operation: 'getappadmin',
                    id_four: ApiService.user().account.id_entite,
                    marque: this.selectedMarque ? this.selectedMarque['Marque'] : '',
                    categ: this.selectedCategorie ? this.selectedCategorie.id_categorie : '',
                    id_appareil: defaultEan ? defaultEan : (this.searchEan ? this.searchEan : ''),
                }, 'districloud_no_action')
                this.products = null
                this.products = response.data.posts.post ? response.data.posts.post : []
                if (selectFirst) {
                    this.selectProduct(this.products[0], defaultEan ? false : true)
                } else {
                    this.selectedProduct = null
                }
            }, 400)
        },

        async selectProduct(product, selectMarque = true) {
            if (!product) {
                return
            }
            let response = await this.$request.post('backoffice', {
                operation: 'getinfoproduct',
                id_appareil: product.id
            }, 'districloud_no_action')
            this.selectedProduct = response.data.posts.post[0]
            this.selectedProduct.id_categorie = this.allCategories.find((item) => item.id_categorie == this.selectedProduct.id_categorie)
            this.selectedProduct.url_notice = this.selectedProduct.notice
            this.selectedProduct.url_photo = this.selectedProduct.img
            this.selectedProduct.url_vue = this.selectedProduct.vue
            if (selectMarque) {
                this.marqueInp = {Marque: product['Marque']}
            }
        },

        async addProduct() {
            let endObj = {...this.newProductForm}
            endObj['Marque'] = this.marqueInp
            this.marqueError = null

            if (!this.$validation.check([
                {value: endObj['Ean'], rules: 'required|minLength:13|maxLength:13|num', id: 'ean'},
                {value: endObj['Modele'], rules: 'required', id: 'modele'},
                {value: endObj['Libelle'], rules: 'required', id: 'libelle'},
                {value: endObj.id_categorie, rules: 'required', id: 'categorie'},
                {value: endObj.poids, rules: 'num|min:0', id: 'poids'},
            ])) {return}            

            if (!endObj['Marque']) {
                this.marqueError = this.$tt('Champ obligatoire')
                return
            }

            this.loadingAddApp = true
            let response = await this.$request.post('backoffice', {
                operation: 'addapp',
                id_four: ApiService.user().account.id_entite,
                ean: endObj['Ean'],
                marque: endObj['Marque']['Marque'],
                modele: endObj['Modele'],
                categ: endObj.id_categorie,
                libelle: endObj['Libelle'],
                ref_com: endObj.ref_commercial ? endObj.ref_commercial : '',
                poids: endObj.poids ? endObj.poids : 0,
                url_photo: endObj.url_photo ? endObj.url_photo : '-1',
                url_notice: endObj.url_notice ? endObj.url_notice : '-1',
                url_vue: endObj.url_vue ? endObj.url_vue : '-1',
                procedures: JSON.stringify(Object.values(this.selectedProcs).filter(n => n))
            }, 'districloud_no_action')
            
            // if (this.applyProcs) {
            //     await this.$request.post('procedure.apply-categ-proc', {
            //         app_id: response.data.posts.post,
            //         exceptions: JSON.stringify(this.importProcsExceptions)
            //     })
            // }
            this.loadingAddApp = false

            if (response.data.posts.error == 0) {
                this.$modal.hide('modal-add-product')
                await this.getMarques()
                await this.getCategories()
                await this.getProducts(endObj['Ean'], true)
                this.$toast.success(this.$tt('Appareil ajouté'))
                this.newProductForm = {}
                this.$nextTick(() => {
                    this.marqueInp = null
                    this.$forceUpdate()
                })
            } else {
                this.$toast.error(response.data.posts.error)
            }
        },


        downloadStackErrors() {
            var element = document.createElement('a');
            let endText = ''
            this.importationResultsErrors.forEach((error) => {
                endText += ('Ligne ' + (error[0].line + 1) + (error[0].ean ? (' - ean: ' + error[0].ean) : '')) + '\n'
                error.forEach((errorMsg) => {
                    endText += '  - ' + errorMsg.error + '\n'
                })
                endText += '\n --------------- \n'
            })
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(endText));
            element.setAttribute('download', 'districloud-importation-appareil-erreurs');

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        },

        async updatedPattern() {
            await this.getPatterns()
            this.$modal.hide('modal-edit-add-proc')
        },

        async getPatterns() {
            var groupBy = function(xs, key) {
                return xs.reduce(function(rv, x) {
                    (rv[x[key]] = rv[x[key]] || []).push(x);
                    return rv;
                }, {});
            };

            let response = await this.$request.post('procedures.get-patterns')
            this.allProcedures = response.data.posts.post
            this.procedures = groupBy(response.data.posts.post.filter((item) => item.selfcare_proc == 0), 'type_garantie')
            for (let key in this.procedures) {
                this.selectedProcs[key] = null
            }
        },

        async getProcOnApp() {
            let response = await this.$request.post('procedures.get-proc-on-app', {
                app_id: this.selectedProduct.id
            })
            this.selectedProduct.procedures = response.data.posts.post
            this.$forceUpdate()
        },

        selectConfigProc(procType, item) {
            if (item) {
                this.selectedConfigs[procType] = item.id
                this.$forceUpdate()
            }
        },

        getProcByConfigId(configId) {
            let config = null
            if (this.allProcedures && this.allProcedures.length > 0) {
                this.allProcedures.forEach((proc) => {
                    let configExists = proc.configs.find((config) => config.id == configId)
                    if (configExists) {
                        config = proc
                    }
                })
            }
            return config
        },

        async addNewCateg() {
            if (!this.$validation.check([
                {value: this.newCategName, rules: 'required', id: 'new-categ-name'},
            ])) {return}

            this.addCategLoader = true
            let response = await this.$request.post('procedures.add-categ', {
                name: this.newCategName
            })

            if (response.data.posts.post == 1) {
                await this.getAllCategories()
                this.$forceUpdate()
                this.newProductForm['id_categorie'] = this.allCategories.find((item) => item.fr == this.newCategName)?.id_categorie                
                this.$toast.success('Catégorie ajoutée')
                this.newCategName = null
                this.$modal.hide('modal-add-categ')
            }
            this.addCategLoader = false
        },

        async saveAlertMsg() {
            let response = await this.$request.post('products.edit-add-alert-msg', {
                app_id: this.selectedProduct.id,
                comment: this.newAlertMsg,
                all_categ: this.alertMsgCateg ? 1 : 0
            })
            if (response.data.posts.post == 1) {
                await this.selectProduct(this.selectedProduct)
                this.$toast.success(this.$tt('Message ajouté'))
                this.$modal.hide('modal-edit-alert-product')
            }
        },

        removeConfig(configKey) {
            delete this.selectedConfigs[configKey]
            this.$forceUpdate()
        },

        // async getProcsOnCateg() {
        //     if (this.newProductForm && this.newProductForm['id_categorie'] && this.newProductForm.id_categorie.fr) {
        //         this.categProcs = []
        //         this.loadingGetProcs = true
        //         let response = await this.$request.post('categ.get-procs-on-categs', {
        //             categs: JSON.stringify([this.newProductForm['id_categorie'].fr])
        //         })
        //         this.categProcs = response.data.posts.post
        //         this.categProcs.forEach((categ) => {
        //             categ.procedures = categ.procedures.map((item) => ({...item, actif: true}))
        //         })
        //         this.loadingGetProcs = false
        //     }
        // },

        getDisabledProcs(procs) {
            this.importProcsExceptions = procs.filter((item) => item.actif == 0).map((item) => {return item.id})
        }
    },
    async mounted () {
        // Tracker
        let user = this.$request.user()
        console.log(user);
        
        if (location.hostname != "localhost" && location.hostname != "127.0.0.1" && user.account.id_entite == 734 && user.account.type_entite == 1) {            
            window.LogRocket && window.LogRocket.init('omg4ub/districloud-1qc5x');
        } 

        await this.getClient()
        await this.getAllCategories()
        await this.getMarques()
        await this.getCategories()
        await this.getEnseignes()
        await this.getPatterns()       

        // if (localStorage.getItem('import-file-products')) {
        //     this.$modal.show('modal-import-list')
        // }
    },
}
</script>
