<template>
    <div>
        <input
        type="file"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-1.5"
        :id="labelToId"
        :placeholder="placeholder"
        :accept="fileType ? fileType : 'image/*,application/pdf'"
        @change="upload"
        :disabled="disabled"
        >

        <spinner class="my-3" v-if="loadingUpload" :size="10" />

        <div v-if="fileUrl && !loadingUpload">
            <a :href="fileUrl" target="_blank" class="text-xs text-blue-500 hover:underline">
                <i class="mr-1 fas fa-link"></i> Consulter le fichier
            </a>
        </div>
    </div>
</template>

<script>
import apiUploadService from '@/services/apiUpload.service'
import Compressor from 'compressorjs';

export default {
    props: {
        label: {
            type: String,
        },
        classSrc: {
            type: String
        },
        placeholder: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        },
        preview: {
            default: true
        },
        defaultData: {},
        id: {},
        fileType: {},
    },
    watch: {
        defaultData() {
            this.setDefaultData()
        }
    },
    data() {
        return {
            loadingUpload: false,
            fileUrl: null,
            fileData: null
        }
    },
    computed: {
        labelToId: function() {
            if (this.label) {
                /* eslint-disable */
                return this.label.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,"").replace(/\s/g, '-').normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
            }
            return this.id
        }
    },
    methods: {
        async uploadFile(fileInput) {
            if (fileInput) {
                this.fileUrl = null
                let response = await apiUploadService.post({
                    files: fileInput
                })
                this.fileData = response.data.files[0]
                if (this.fileData) {
                    if (this.preview) {
                        this.fileUrl = this.fileData.url
                    }
                    // Emit Parent
                    this.$emit('input', this.fileData)
                }
            } else {
                this.fileUrl = null
            }
        },

        compressFile(file) {
            if (!file) {
                return;
            }

            new Compressor(file, {
                quality: 0.5,
                convertSize: 0,
                convertTypes: ['image/gif', 'image/jpeg', 'image/png', 'image/heic', 'image/webp'],

                success: async (result) => {
                    if (result.size < 5000000) {
                        await this.uploadFile(result)
                    } else {
                        this.$toast.error(this.$t('composants.sav-input-file-upload.fichier_trop_volumineux'))
                    }
                    this.loadingUpload = false
                },
                error(err) {
                    console.warn('Error image compression:');
                    console.log(err.message);
                    return file
                },
            });
        },

        async upload(event) {
            // Loader
            this.loadingUpload = true

            // Set data
            let fileInput = event.target.files.length > 0 ? event.target.files[0] : null

            // Compress image
            if (fileInput) {
                let isFileImage = (file) => {
                    const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/heic', 'image/webp'];
                    return file && acceptedImageTypes.includes(file['type'])
                }
                if (isFileImage(fileInput)) {
                    this.compressFile(fileInput)
                    return
                }
            }

            // Api
            await this.uploadFile(fileInput)

            // Loader
            this.loadingUpload = false
        },

        setDefaultData() {
            if (this.defaultData) {
                this.fileData = this.defaultData
                if (this.preview) {
                    this.fileUrl = this.defaultData.url
                }
            }
        }
    },
    mounted() {
        this.setDefaultData()
    }
}
</script>
