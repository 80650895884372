<template>
    <div>
        <div class="container mx-auto h-screen overflow-y-auto">
            <div class="p-4 flex items-center h-screen justify-center" v-if="loadingGetData">
                <spinner :size="20" />
            </div>
            <div v-if="dossier && !loadingGetData">
                <div class="lg:grid grid-cols-8 gap-4">
                    <div class="col-span-3 p-4">
                        <div class="bg-slate-100 rounded-xl p-4 border shadow sticky top-10">
                            <img class="h-[70px]" :src="dossier.station.logo" alt="">
                            <h2 class="text-lg font-bold mt-2">{{ dossier.station.nom }}</h2>
                            <h4 class="font-light mt-1 border-b border-slate-300 mb-4 pb-4">{{ dossier.station.adresse + ' ' + dossier.station.cp + ' ' + dossier.ville }}</h4>
                            <h5 class="">{{ dossier.station.tel }}</h5>
                            <h5 class="">{{ dossier.station.mail }}</h5>
                        </div>
                    </div>
                    <div class="col-span-5 p-4">
                        <div class="p-4">
                            <h2 class="font-bold text-3xl">Bonjour {{ dossier.end_customer.prenom ? dossier.end_customer.prenom : '' }}</h2>
                            <h4 class="mt-2 text-xl">Merci de fournir plus d'informations concernant votre appareil {{ dossier.device['Marque'] + ' - ' + dossier.device['Modele'] }}</h4>
                            <div class="lg:overflow-y-auto h-[80vh] mt-2">
                                <div v-for="(question, questionIndex) in dossier.complements" :key="'question-' + questionIndex" class="mt-4 border rounded-xl p-4 shadow">
                                    <div class="grid grid-cols-2 gap-10">
                                        <div>
                                            <!-- <h2 class="font-light text-sm mb-1">Question technicien:</h2> -->
                                            <div class="text-xs font-light mt-2">{{question.question_date | momentDate}}</div>
                                            <h3 class="font-bold" v-html="question.question"></h3>
                                            <div class="border-t mt-2 pt-2" v-if="question.question_files && JSON.parse(question.question_files).length > 0">
                                                <div v-for="(file, fileIndex) in JSON.parse(question.question_files)" :key="'file-comp-' + fileIndex" class="flex items-center gap-2 mt-1">
                                                    <a target="_blank" :href="file" class="text-blue-500 text-sm hover:underline"><i class="fas fa-link mr-1"></i> {{$tt('Fichier') + ' #' + (fileIndex + 1) }}</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="p-4" v-if="question.loading">
                                                <spinner :size="10" />
                                            </div>
                                            <div v-if="!question.loading">
                                                <h2 class="font-light text-sm mb-1">Votre réponse:</h2>
                                                <template v-if="!question.hasReponse">
                                                    <input-rule v-model="question.reponse" type="richtext" :label-inline="false" :id="'inp-reponse-' + question.id" />
                                                    <input-rule type="file" @input="(data) => { if (data) {attachFiles.push(data.url)}}" :label="$tt('Joindre un fichier')" :label-inline="false" class="mt-2" :options="{preview: false}" />
                                                    <div v-for="(file, fileIndex) in attachFiles" :key="'file-comp-' + fileIndex" class="flex items-center gap-2 mt-1">
                                                        <button @click="attachFiles.splice(fileIndex, 1)"><i class="fas fa-times text-red-500"></i></button> <a target="_blank" :href="file" class="text-blue-500 hover:underline">{{$tt('Fichier') + ' #' + (fileIndex + 1) }}</a>
                                                    </div>
                                                    <div class="mt-2 flex items-center justify-end gap-2">
                                                        <button :disabled="question.loading" @click="sendReponse(question)" class="px-4 py-2 text-xs bg-green-600 hover:bg-green-700 rounded text-white font-bold"><i class="fas fa-check mr-1"></i> Répondre</button>
                                                    </div>
                                                </template>
                                                <div class="bg-slate-100 rounded p-3 text-sm" v-if="question.hasReponse" v-html="question.reponse"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    data() {
        return {
            dossier: null,
            attachFiles: [],
            loadingGetData: false
        }
    },
    filters: {
        momentDate(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY')
            }
            return '-'
        },
    },
    methods: {
        async getDossier(init = true) {
            if (init) {
                this.loadingGetData = true
            }
            let response = await this.$request.post('technicien.get-dossier-complement-token', {
                dossier_token: this.$route.params.dossierToken
            })
            this.loadingGetData = false
            if (response.data.posts.post == 0) {
                this.$toast.error(this.$tt('Dossier introuvable'))
                return
            }
            this.dossier = response.data.posts.post
            this.dossier.complements = this.dossier.complements.map((item) => ({...item, loading: false})).reverse()
        },

        async sendReponse(question) {
            if (!this.$validation.check([
                {value: question.reponse, rules: 'required', id: 'inp-reponse-' + question.id},
            ])) {return}

            question.loading = true
            await this.$request.post('technicien.send-reponse-complement', {
                token_dossier: this.$route.params.dossierToken,
                reponse: question.reponse,
                id_question: question.id,
                q_files: JSON.stringify(this.attachFiles)
            })
            question.hasReponse = true
            question.loading = false
            this.getDossier(false)
            this.$toast.success(this.$tt('Réponse envoyée'))
        }
    },
    mounted () {
        this.getDossier()
    },
}
</script>

<style lang="scss" scoped>

</style>