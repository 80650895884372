var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('modal',{attrs:{"name":"modal-confirm-resend","height":"auto","scrollable":true}},[_c('div',{staticClass:"p-10"},[(_vm.selectedLine)?[_c('div',{staticClass:"flex items-center justify-between mb-4 pb-4 border-b text-2xl"},[_c('h2',{staticClass:"font-bold"},[_vm._v(" Renvoyer le mail ")]),_c('button',{on:{"click":function($event){_vm.$modal.hide('modal-confirm-resend'); _vm.selectedLine = null}}},[_c('i',{staticClass:"fas fa-times text-red-500"})])]),_c('div',{staticClass:"p-4 mb-4 text-sm text-orange-800 rounded-lg bg-orange-50",attrs:{"role":"alert"}},[_c('p',[_vm._v("Êtes-vous sûr de vouloir renvoyer ce mail à "),_c('b',[_vm._v(_vm._s(_vm.selectedLine.to))]),_vm._v(" ?")])]),_c('button',{staticClass:"bg-emerald-600 hover:bg-emerald-700 duration-200 cursor-pointer text-white px-4 py-2 rounded text-sm",attrs:{"disabled":_vm.loaderModal},on:{"click":_vm.resendMail}},[(!_vm.loaderModal)?[_vm._v(" Confirmer "),_c('i',{staticClass:"fas fa-check ml-1"})]:_vm._e(),(_vm.loaderModal)?[_c('spinner',{attrs:{"size":10}})]:_vm._e()],2)]:_vm._e()],2)]),_c('modal',{attrs:{"name":"modal-display-mail","height":"auto","width":"90%","scrollable":true}},[(_vm.selectedLine)?[_c('div',{staticClass:"p-4"},[_c('div',{staticClass:"block mb-1 text-xs text-gray-900"},[_vm._v("Envoyé à: "),_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.selectedLine.to))])]),_c('div',{staticClass:"block mb-1 text-xs text-gray-900"},[_vm._v("Sujet: "),_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.selectedLine.subject))])])]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.selectedLine.content)}})]:_vm._e()],2),_c('app-template',[_c('div',{staticClass:"bg-white p-10 rounded shadow"},[_c('data-table',{attrs:{"server":true,"filters":[
                {label: 'Envoyé à', key: 'to', type: 'text', value: null},
                {label: 'Sujet', key: 'subject', type: 'text', value: null},
                {label: 'Etiquette', key: 'tag', type: 'select', options: [
                    {label: 'Aucun', value: ''},
                    {label: 'Inscription', value: 'inscription'},
                    {label: 'Ecosystème', value: 'ecosystem'},
                    {label: 'Site vitrine', value: 'site_vitrine'},
                ]},
            ],"columns":[
                {label: 'Date', key: 'created_at', type: 'datetime'},
                {label: 'Envoyé à', key: 'to', type: 'text'},
                {label: 'Subject', key: 'subject', type: 'text'},
                {label: 'Etiquette', slot: 'tag', type: 'text'},
                {label: 'Ouvert', key: 'open', type: 'boolean'},
            ],"request":{
                name: 'email.list',
                params: {
                    page: 0,
                    per_page: 50,
                },
                apiName: 'api2'
            }},scopedSlots:_vm._u([{key:"tag",fn:function({line}){return [(_vm.tagConfig[line.tag])?_c('span',{staticClass:"text-white py-1 px-2 text-xs rounded",style:('background: ' + _vm.tagConfig[line.tag].color)},[_vm._v(" "+_vm._s(_vm.tagConfig[line.tag].label)+" ")]):_vm._e()]}},{key:"actions",fn:function({line}){return [_c('div',{staticClass:"flex items-center"},[_c('button',{staticClass:"flex items-center mr-1 text-xs bg-slate-200 hover:bg-slate-300 duration-200 cursor-pointer font-light px-3 py-1.5 rounded",on:{"click":function($event){_vm.selectedLine = line; _vm.$modal.show('modal-confirm-resend')}}},[_c('i',{staticClass:"mr-1 fa-solid fa-rotate-right"}),_vm._v(" Renvoyer ")]),_c('button',{staticClass:"mr-1 text-xs bg-orange-500 hover:bg-orange-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded text-white",on:{"click":function($event){_vm.selectedLine = line; _vm.$modal.show('modal-display-mail')}}},[_c('i',{staticClass:"fas fa-eye"})])])]}}])})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }